import { TTranslator } from '@smartshell/ui-kit';

import { TLocalesKeys } from 'localization';

export const getTimes = (
  duration: number,
  t: TTranslator<TLocalesKeys>,
): string => {
  if (duration % 3600 === 0) {
    return `${Math.floor(duration / 3600)} ${t('hour')}`;
  }

  return `${Math.floor(duration / 60)} ${t('minutes')}`;
};
