import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import { ThemeProvider, TokenProvider } from 'providers';

import s from './AppLayout.module.scss';

export const AppLayout: FC = () => (
  <ThemeProvider>
    <TokenProvider>
      <div className={s.app}>
        <Outlet />
      </div>
    </TokenProvider>
  </ThemeProvider>
);
