import { createBrowserRouter, Navigate } from 'react-router-dom';

import { AppLayout, AuthLayout } from 'layouts';

export const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          { path: '/login', lazy: () => import('./pages/Login/Login') },
          // {
          //   path: '/register',
          //   lazy: () => import('./pages/Register/Register'),
          // },
          {
            path: '/recovery',
            lazy: () => import('./pages/Recovery/Recovery'),
          },
        ],
      },
      { path: '/', lazy: () => import('./pages/Home/Home') },
      { path: '/profile', lazy: () => import('./pages/Profile/Profile') },
      { path: '/password', lazy: () => import('./pages/Password/Password') },
      { path: '/clubs', lazy: () => import('./pages/Clubs/Clubs') },
      { path: '/clubs/:id', lazy: () => import('./pages/Club/Club') },
      {
        path: '/clubs/:id/deposit',
        lazy: () => import('./pages/Deposit/Deposit'),
      },
      {
        path: '/clubs/:id/booking',
        lazy: () => import('./pages/Booking/Booking'),
      },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
]);
