import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

type Theme = 'light' | 'dark';

type ThemeContext = {
  theme: Theme;
  toggleTheme: VoidFunction;
};

export const ThemeContext = createContext<ThemeContext>({
  theme: 'light',
  toggleTheme: () => null,
});

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(() => {
    const LSValue = localStorage.getItem('theme') as Theme;
    const prefersDarkTheme = matchMedia('(prefers-color-scheme: dark)').matches;
    return LSValue || (prefersDarkTheme ? 'dark' : 'light');
  });

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.classList.add(theme);
    document.body.classList.remove(theme === 'light' ? 'dark' : 'light');
  }, [theme]);

  const toggleTheme = (): void => {
    document.body.classList.add('bg-transition');
    setTimeout(() => {
      document.body.classList.remove('bg-transition');
    }, 300);
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
