export const getCeilNum = (num: number): number => {
  const arr = String(num).split('.');

  if (arr.length === 1 || arr[1].length <= 2) return num;

  const twoNum = Number(arr[1].substr(1, 1)) + 1;
  let newNum = Number(arr[1].substr(0, 1));

  if (twoNum === 10) {
    newNum += 1;
  } else {
    newNum = newNum * 10 + twoNum;
  }

  return Number(arr[0] + '.' + newNum);
};
