import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useApolloClient } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  getTokenFromStorage,
  removeTokenFromStorage,
  setTokenToStorage,
} from 'utils';

type AuthContext = {
  token: string | null;
  setToken: (token: string) => void;
  logout: VoidFunction;
};

export const TokenContext = createContext<AuthContext>({
  token: null,
  setToken: () => null,
  logout: () => null,
});

const AUTH_ROUTES = ['register', 'recovery', 'login'] as const;

export const TokenProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAuthZone = AUTH_ROUTES.some((route) => pathname.includes(route));

  const [token, setToken] = useState(getTokenFromStorage());
  const saveToken = useCallback((token: string) => {
    setToken(token);
    setTokenToStorage(token);
  }, []);

  const client = useApolloClient();

  const logout = useCallback(() => {
    client.clearStore().then(() => {
      removeTokenFromStorage();
      setToken(null);

      navigate('/login');
    });
  }, []);

  useEffect(() => {
    if (token || isAuthZone) {
      return;
    }

    logout();
  }, [token, isAuthZone]);

  useEffect(() => {
    const storageTokensChecker = (): void => {
      if (isAuthZone) {
        return;
      }

      if (document.visibilityState === 'visible') {
        const token = getTokenFromStorage();

        if (!token) {
          logout();
        }
      }
    };

    document.addEventListener('visibilitychange', storageTokensChecker);

    return () =>
      document.removeEventListener('visibilitychange', storageTokensChecker);
  }, [isAuthZone]);

  return (
    <TokenContext.Provider
      value={{
        token,
        setToken: saveToken,
        logout,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};
