import { StrictMode } from 'react';

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Localization } from '@smartshell/ui-kit';
import { locale } from 'dayjs';
import ru from 'dayjs/locale/ru';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { LocalizationContext, LOCALES_MAP } from 'localization';
import { createTagManager } from 'services';
import { getHTTPAuthHeader, removeTokenFromStorage } from 'utils';

import { router } from './router';

import '@smartshell/ui-kit/dist/styles.css';
import './style.scss';

locale(ru);

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/api/graphql`,
});

const authLink = setContext((request, { headers }) => ({
  headers: {
    ...headers,
    ...getHTTPAuthHeader(),
  },
}));

const errorLink = onError(({ graphQLErrors }) => {
  const isUnauthorized = graphQLErrors?.some(
    (error) => error?.extensions?.code === 401,
  );

  if (isUnauthorized) {
    removeTokenFromStorage();
    window.location.reload();
  }
});

if (process.env.NODE_ENV === 'production') {
  createTagManager();
}

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        keyFields: ['uuid'],
      },
    },
  }),
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <ApolloProvider client={client}>
      <Localization
        context={LocalizationContext}
        locales={LOCALES_MAP}
        useKeyboardHandler
      >
        <RouterProvider router={router} />
      </Localization>
    </ApolloProvider>
  </StrictMode>,
);
