import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import s from './AuthLayout.module.scss';

export const AuthLayout: FC = () => {
  return (
    <div className={s.root}>
      <div className={s.id}>SmartShell ID</div>
      <Outlet />
    </div>
  );
};
