import { TTranslator } from '@smartshell/ui-kit';
import dayjs from 'dayjs';

import { TLocalesKeys } from 'localization';
import { MyClub, PaymentTransaction } from 'schemas';

const STORAGE_TOKEN_KEY = 'token';

export const getTokenFromStorage = (): string | null => {
  const token = localStorage.getItem(STORAGE_TOKEN_KEY);
  return token ? JSON.parse(token) : null;
};

export const setTokenToStorage = (token: unknown): void => {
  localStorage.setItem(STORAGE_TOKEN_KEY, JSON.stringify(token));
};

export const getHTTPAuthHeader = (): { Authorization?: string } => {
  const token = getTokenFromStorage() || {};

  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const removeTokenFromStorage = (): void => {
  localStorage.removeItem(STORAGE_TOKEN_KEY);
};

export function debounce<F extends VoidFunction>(
  this: unknown,
  func: F,
  timeout: number,
): (...args: Parameters<F>) => void {
  let timer: NodeJS.Timeout;

  return (...args: Parameters<F>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const formatPhone = (p: string): string =>
  p.length !== 11
    ? p
    : `+${p[0]} (${p.slice(1, 4)}) ${p.slice(4, 7)}-${p.slice(7, 9)}-${p.slice(
        9,
        11,
      )}`;

export const groupDayToClubs = (
  clubsArr: readonly MyClub[],
): Map<string, Set<MyClub>> => {
  const sortedByDateClubsArr = [...clubsArr].sort(
    (a, b) =>
      new Date(b.last_visited_at).getTime() -
      new Date(a.last_visited_at).getTime(),
  );

  const map = new Map<string, Set<MyClub>>();
  const currentDateObj = dayjs();

  for (const club of sortedByDateClubsArr) {
    if (!club.name) {
      continue;
    }

    const lastVisitedDateObj = dayjs(club.last_visited_at);
    const yearTemplate = currentDateObj.isSame(lastVisitedDateObj, 'year')
      ? ''
      : ' YYYY';

    const dateKey = lastVisitedDateObj.format(`D MMMM${yearTemplate}`);

    const mappedClubs = map.get(dateKey);

    if (mappedClubs) {
      mappedClubs.add(club);
    } else {
      map.set(dateKey, new Set([club]));
    }
  }

  return map;
};

export const groupMonthToVisitDateTime = ({
  visits,
  last_visited_at,
}: MyClub): Map<string, string[]> => {
  const map = new Map<string, string[]>();
  const allVisits = visits
    .map((visit) => visit.visited_at)
    .concat(last_visited_at)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

  for (const visit of allVisits) {
    const dateObj = dayjs(visit);
    const monthKey = dateObj.format('MMMM');
    const dateTime = dateObj.format('D MMMM-HH:mm');
    const mappedVisits = map.get(monthKey);
    if (mappedVisits) {
      mappedVisits.push(dateTime);
    } else {
      map.set(monthKey, [dateTime]);
    }
  }

  return map;
};

export type TransactionTuple = [string, PaymentTransaction['status'], number];

export const groupMonthToTransactionTuple = (
  transactions: readonly PaymentTransaction[],
): Map<string, TransactionTuple[]> => {
  const map = new Map<string, TransactionTuple[]>();
  const currentDateObj = dayjs();

  for (const transaction of transactions) {
    const createdAtDateObj = dayjs(transaction.created_at);
    const yearTemplate = createdAtDateObj.isSame(currentDateObj, 'year')
      ? ''
      : ' YYYY';

    const monthKey = createdAtDateObj.format(`MMMM${yearTemplate}`);

    const dateTime = createdAtDateObj.format(`D MMMM${yearTemplate} HH:mm`);
    const mappedVisits = map.get(monthKey);
    if (mappedVisits) {
      mappedVisits.push([dateTime, transaction.status, transaction.amount]);
    } else {
      map.set(monthKey, [[dateTime, transaction.status, transaction.amount]]);
    }
  }

  return map;
};

export const formatDeposit = (deposit?: number): string | undefined =>
  deposit?.toLocaleString(undefined, { maximumFractionDigits: 2 });

export const startTransition = (navigate: VoidFunction): void => {
  if (!('startViewTransition' in document)) {
    navigate();
    return;
  }

  document.startViewTransition(navigate);
};

export const startBackTransition = async (
  navigate: VoidFunction,
): Promise<void> => {
  if (!('startViewTransition' in document)) {
    navigate();
    return;
  }

  document.documentElement.classList.add('back-transition');

  const transition = document.startViewTransition(navigate);

  try {
    await transition.finished;
  } finally {
    document.documentElement.classList.remove('back-transition');
  }
};

export const convertTransactionStatusToLabel = (
  transactionStatus: PaymentTransaction['status'],
  t: TTranslator<TLocalesKeys>,
): string => {
  switch (transactionStatus) {
    case 'success':
      return '';
    case 'pending':
      return t('transactions.pending');
    case 'canceled':
      return t('transactions.canceled');
    case 'failed':
    case 'expired':
      return t('transactions.failed');
  }
};
