import { createLocalizationContext, ELanguage } from '@smartshell/ui-kit';

import type ru from './locales/ru.json';

export const LocalizationContext = createLocalizationContext<TLocalesKeys>();

export type TLocalesKeys = keyof typeof ru;
export const LOCALES_MAP: Record<
  ELanguage,
  () => Promise<Record<TLocalesKeys, string>>
> = {
  [ELanguage.EN]: () => {
    import('dayjs/locale/en');
    return import('./locales/en.json');
  },
  [ELanguage.RU]: () => {
    import('dayjs/locale/ru');
    return import('./locales/ru.json');
  },
};
